<template>
    <div>
        <a @click="scrollOrPopup" class="btn rnd-secondary-btn balance-width float-lg-left float-none mt-2 text-uppercase balance-width">{{ buttonTitle }}</a>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    name: "call",
    data() {
        return {
            showPopup: false,
        };
    },
    computed: {
        ...mapState("willyou", ["currentStore"]),
        buttonTitle() {
            return this.currentStore && this.currentStore.onlyLead === true ? "Contact Jeweler" : "Available Diamond Inventory";
        },
    },
    methods: {
        changePopupStatus() {
            this.showPopup = !this.showPopup;
        },
        scrollOrPopup() {
            if (this.currentStore && this.currentStore.onlyLead === true) {
                this.$emit("showPopup");
            } else {
                this.$scrollTo("#diamond-filters", 500, { offset: -80, force: true });
            }
        },
    },
};
</script>
<style scoped>
p {
    clear: both;
}
.privacy-policy {
    text-decoration: underline !important;
}
</style>
