<template>
    <div class="positivity-score-wrapper">
        <div class="positivity-score-inner">
            <span class="score-num">{{ score }}</span>
            <div class="positivity-score-text-wrapper">
                <span class="title">Positivity Score</span>

                <span class="noto-san-14 text bold-text"> How We Built a Positivity Score You Can Rely On? </span>

                <span class="noto-sans-14 text">
                    Our positivity score reflects genuine customer sentiment by blending positive feedback and neutral experiences and considering negative
                    insights for a balanced perspective you can trust.
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PositivityScore",
    props: {
        score: {
            type: Number,
            default: 0,
        },
    },
};
</script>

<style scoped lang="scss">
.positivity-score-wrapper {
    width: 100%;
    height: 100%;
    background-color: #e4f9f4;

    .positivity-score-inner {
        display: flex;
        align-items: center;
        padding: 0px 20px;
        width: 100%;
        height: 100%;

        @media (max-width: 991px) {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            // height: 250px;
            padding: 20px 0;
            .positivity-score-text-wrapper {
                margin-right: 20px;
            }
        }
    }

    .score-num {
        width: 100%;
        height: 100%;
        max-width: 80px;
        max-height: 80px;
        height: 80px;
        width: 80px;
        border: 2px solid #293887;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #293887;
    }

    .positivity-score-text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;
        color: #4a4a4a;

        .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 12px;
        }

        .text {
            letter-spacing: 2px;
            margin-bottom: 8px;
            line-height: 125%;
        }

        .bold-text {
            font-weight: bold;
        }

        @media (min-width: 992px) {
            width: calc(100% - 100px);
        }
    }
}
</style>
