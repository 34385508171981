<template>
    <div id="map-component" v-if="markers && markers.length && markers[0].lng && getMarkersForMap && getMarkersForMap.length">
        <map-body :markers="markers" :height="height" :zoomLevel="zoomLevel"></map-body>
    </div>

    <div v-else class="img-wrapper"></div>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import MapBodyVue from "./MapBody.vue";

export default {
    name: "map-component",
    components: {
        "map-body": MapBodyVue,
    },
    props: {
        markers: undefined,
        height: undefined,
        zoomLevel: {
            type: Number,
            default: 10,
        },
    },
    data() {
        return {
            info_marker: null,
            currentMarker: null,
        };
    },
    computed: {
        getMarkersForMap() {
            return this.markers && this.markers.length
                ? this.markers.map((m) => {
                      return { position: { lng: m.lng, lat: m.lat }, jewelerName: m.jewelerName ? m.jewelerName : null, html: m.html ? m.html : null };
                  })
                : [];
        },
    },
    created() {
        Vue.use(VueGoogleMaps, {
            load: {
                key: "AIzaSyA2NvrnuWgRnKIJFTl6RZbXSt4hhrFl2PY",
                language: "en",
            },
        });
    },
    methods: {
        onMarkerOver(m) {
            this.currentMarker = m;
        },
        onMarkerLeave() {
            this.currentMarker = null;
        },
        onMarkerClick(m) {
            this.currentMarker = m;
        },
    },
};
</script>

<style lang="scss" scoped>
#map-component {
    height: 100%;
    width: 100%;
}

.img-wrapper {
    height: 100%;
    width: 100%;
    background-image: url("../../../assets/images/store/default-store.png");
    background-size: cover;
    background-position: center;
}
</style>
