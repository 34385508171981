<template>
    <div id="popular-jewelry-store" class="container-fluid mb-4">
        <h2 v-if="!isStatePage" class="home-title section-title">Jewelry stores near {{ getCityState }}</h2>
        <h4 v-if="isStatePage" class="home-title section-title">
            {{ getCaption }}
        </h4>
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-3 pr-0 pl-0 position-relative" v-for="(store, i) in stores" :key="i">
                <popular-jewelry-store-card :store="store" :i="i"></popular-jewelry-store-card>
            </div>
        </div>
    </div>
</template>
<script>
import PopularJewelryStoreCard from "./PopularJewelryStoreCard.vue";
export default {
    name: "popular-jewelry-store",
    props: ["stores", "state"],
    components: {
        "popular-jewelry-store-card": PopularJewelryStoreCard,
    },
    computed: {
        isStatePage() {
            return !this.$route.params.hasOwnProperty("city");
        },
        getStateName() {
            if (typeof this.state === "string") return this.state;
            return this.state !== null && this.state.name ? this.state.name : "";
        },
        getCity() {
            let routeCity = this.$route.params.city.replace(/-/g, " ");
            let cityRecord = this.state && this.state.cities ? this.state.cities.find((city) => city.name.toLowerCase() === routeCity) : null;
            return cityRecord ? cityRecord.name : "";
        },
        getCityState() {
            let city = this.getCity;
            let state = this.state && this.state.shortName ? this.state.shortName : "";
            return city && state ? `${city}, ${state}` : "";
        },
        getCaption() {
            return this.$route.name === "home-store" ? `Other Jewelers in ${this.getStateName}` : `Main Cities in ${this.getStateName}`;
        },
    },
};
</script>

<style lang="scss" scoped>
#popular-jewelry-store {
    .section-title {
        font-size: 36px;
    }
}
</style>
