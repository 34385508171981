<template>
    <div id="map-body" v-if="markers && markers.length && getMarkersForMap && getMarkersForMap.length">
        <GmapMap
            :center="getMarkersForMap[0].position"
            :zoom="zoomLevel"
            :style="`width: auto; height: ${height};transition: top 0.05s ease;`"
            :options="{ styles: style, disableDefaultUI: true }">
            <GmapMarker
                :key="index"
                v-for="(m, index) in getMarkersForMap"
                :position="m.position"
                :clickable="true"
                :draggable="false"
                @click="onMarkerClick(m)"
                @mouseover="onMarkerOver(m)"
                @mouseout="onMarkerLeave()" />
            <GmapInfoWindow
                v-if="currentMarker && currentMarker.position"
                @closeclick="currentMarker = false"
                :opened="currentMarker !== null"
                :position="currentMarker.position"
                :options="{ pixelOffset: { width: 0, height: -35 } }">
                {{ currentMarker.jewelerName }}
                <p v-if="currentMarker.html" v-html="currentMarker.html"></p>
            </GmapInfoWindow>
        </GmapMap>
    </div>
</template>
<script>
import { mapStyle } from "./mapStyle";

export default {
    name: "map-body",
    props: {
        markers: undefined,
        height: undefined,
        zoomLevel: {
            type: Number,
            default: 10,
        },
    },
    data() {
        return {
            style: mapStyle,
            info_marker: null,
            currentMarker: null,
        };
    },
    computed: {
        getMarkersForMap() {
            return this.markers && this.markers.length
                ? this.markers.map((m) => {
                      return { position: { lng: m.lng, lat: m.lat }, jewelerName: m.jewelerName ? m.jewelerName : null, html: m.html ? m.html : null };
                  })
                : [];
        },
    },
    methods: {
        onMarkerOver(m) {
            this.currentMarker = m;
        },
        onMarkerLeave() {
            this.currentMarker = null;
        },
        onMarkerClick(m) {
            this.currentMarker = m;
        },
    },
};
</script>

<style lang="scss" scoped>
#map-body {
    height: 100%;
}
</style>
