<template>
    <div class="w-100 my-4 h-100">
        <div class="col-12 col-md-6 map pr-3 map-container d-inline-block">
            <map-component :zoomLevel="18" :markers="locations" height="100%"></map-component>
        </div>
        <div class="col-md-6 map pl-3 map-container d-inline-block">
            <iframe
                v-if="currentStore && currentStore.streetView"
                :src="currentStore.streetView"
                class="google-iframe"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"></iframe>
            <img
                v-else
                class="mx-md-auto ml-lg-auto d-block img-fluid p-lg-0"
                :alt="currentStore && currentStore.companyName"
                :src="currentStore && currentStore.images && currentStore.images.photo1 ? currentStore.images.photo1 : `/images/stores/1.png`" />
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import MapComponent from "../../../components/map/Map.vue";

export default {
    name: "google-maps-streetview",
    components: {
        "map-component": MapComponent,
    },
    computed: {
        ...mapState("willyou", ["currentStore"]),
        locations() {
            return this.currentStore && this.currentStore.companyName
                ? [
                      {
                          ...this.currentStore.location,
                          jewelerName: this.currentStore.companyName,
                      },
                  ]
                : [];
        },
    },
};
</script>
<style lang="scss" scoped>
.map-container {
    height: 518px;
}
iframe {
    height: 100%;
    width: 100%;
}
@media screen and (max-width: 768px) {
    .map-container {
        max-height: 40vh;
    }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
    .map-container {
        height: 311px;
    }
}
@media screen and (max-width: 1200px) {
    .map-container {
        max-height: 430px;
    }
}
</style>
