<template>
    <div id="opening-hours-display">
        <span class="font-weight-bold">{{ headline }}: </span>
        <span class="w-100 d-block" v-for="(hour, i) in getHoursToDisplay" :key="i">{{ hour }}</span>
    </div>
</template>

<script>
export default {
    name: "opening-hours-display",
    props: ["headline", "hours-object"],
    computed: {
        getHoursToDisplay() {
            return this.hoursObject && this.hoursObject.length
                ? this.hoursObject
                      .filter((h) => h.closeTime && h.openTime)
                      .map((h) => {
                          h.openTime.hours = this.isSingleDigit(h.openTime.hours) ? `0${h.openTime.hours}` : h.openTime.hours;
                          h.openTime.minutes = this.isSingleDigit(h.openTime.minutes) ? `0${h.openTime.minutes}` : h.openTime.minutes;
                          h.closeTime.hours = this.isSingleDigit(h.closeTime.hours) ? `0${h.closeTime.hours}` : h.closeTime.hours;
                          h.closeTime.minutes = this.isSingleDigit(h.closeTime.minutes) ? `0${h.closeTime.minutes}` : h.closeTime.minutes;
                          let isApptOnly = h.isAppointmentOnly === true ? " (BY APPOINTMENT ONLY)" : "";
                          if (h.openTime.hours !== "00" || h.openTime.minutes !== "00") {
                              return `${h.weekday}: ${h.openTime.hours}:${h.openTime.minutes} ${h.openTime.ampm.toUpperCase()} - ${h.closeTime.hours}:${
                                  h.closeTime.minutes
                              } ${h.closeTime.ampm.toUpperCase()} ${isApptOnly}`;
                          }
                      })
                : [];
        },
    },
    methods: {
        isSingleDigit(numOrChar) {
            return numOrChar.toString().length === 1;
        },
    },
};
</script>
