<template>
    <div :id="`store-card-`">
        <a @click.prevent="sendGaClickEvent(linkDestination)" :href="linkDestination">
            <div class="card store-card">
                <div class="card-body">
                    <div v-if="!isStatePage" class="row justify-content-center">
                        <div class="col-6">
                            <img
                                class="d-block img-fluid store-logo"
                                :alt="`${store.url}_logo`"
                                :src="getLogoSrc"
                                @error="this.src = 'images/store/default.png'" />
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12 position-absolute bottom">
                            <div class="box d-flex justify-content-center flex-column" itemtype="https://schema.org/Store">
                                <span v-if="!isStatePage" class="store-name" itemtype="https://schema.org/legalName">
                                    {{ store.companyName }}
                                </span>
                                <span v-if="isStatePage" class="store-name" itemtype="https://schema.org/legalName">
                                    {{ store.name }}
                                </span>
                                <p v-if="!isStatePage" class="address" itemtype="https://schema.org/address">{{ getSemiAddress }} {{ getDistance }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: "store-card",
    props: ["store", "i"],
    computed: {
        getLogoSrc() {
            return this.store && this.store.images && this.store.images.logo
                ? this.store.images.logo
                : require("./../../../../assets/images/category/store-logo.svg");
        },
        getSemiAddress() {
            return this.store
                ? `${this.store.city ? this.store.city : ""} ${this.store.city && this.store.state ? "," : ""}
                ${this.store.state ? this.store.state : ""}`
                : "";
        },
        getDistance() {
            return this.store && this.store.distance ? `(${this.store.distance} mi)` : "";
        },
        isStatePage() {
            return !this.$route.params.hasOwnProperty("city") && this.$route.name !== "home-store";
        },
        cityName() {
            return this.store.name.toLowerCase().replace(" ", "-");
        },
        linkDestination() {
            return this.isStatePage ? `/jewelry-stores/${this.$route.params.stateName.toLowerCase()}/${this.cityName}` : `/jewelers/${this.store.url}`;
        },
    },
    methods: {
        sendGaClickEvent(linkDestination) {
            let googleAnalyticsObject = {
                event: "product click",
                ecommerceLabel: "jeweler state-cities",
                ecommerce: {
                    click: {
                        actionField: { list: "jeweler state-cities" },
                        products: [
                            {
                                name: this.store.name,
                                position: this.store.position,
                            },
                        ],
                    },
                },
            };
            window.dataLayer.push(googleAnalyticsObject);
            this.$router.push({ path: linkDestination });
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
};
</script>

<style lang="scss" scoped>
.store-card {
    cursor: pointer;
    border: solid 1px #c3c3c3;
    border-radius: 0;
    border-bottom: 1px solid #767d985e;
    color: #4a4a4a;
    height: 180px;
    margin-top: -1px;
    margin-left: -1px;
    font-family: "Noto Sans KR", sans-serif;

    img {
        width: 100%;
    }

    .bottom {
        bottom: 1.25rem;
    }

    .choose-btn {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #000000;
        padding: 0;

        .btn-text {
            color: #ff0a53;
        }
    }

    .store-card-county-name {
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: 2px;
        color: #ff0a53;
        font-size: 12px;
        margin-bottom: 15px;
    }

    .store-logo {
        max-height: 63px;
    }

    .store-name {
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: 2px;
        font-weight: bold;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 0;
    }

    p.address,
    p.contact {
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: 2px;
        font-size: 12px;

        a {
            margin-left: 15px;
            text-decoration: underline !important;
        }
    }
}

.first-card {
    border-top: 1px solid #767d985e;
}
</style>
