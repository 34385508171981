<template>
    <div id="shapes" class="form-group">
        <div class="btn-group flex-wrap" role="group" id="diamondShapes">
            <button
                v-for="(shape, i) in shapes"
                :key="i"
                class="btn btn-filters btn-filters-sm tiny-filter-btn p-0"
                :class="{
                    'rnd-primary-btn': isIncludes(currentShapes, shape),
                    'rnd-secondary-btn': !isIncludes(currentShapes, shape),
                    'first-btn': i === 0,
                    'last-btn': i == shapes.length - 1,
                }"
                @click.prevent="selectShape(shape)"
                :data-title="shape ? shape.charAt(0).toUpperCase() + shape.slice(1) : ''">
                <img alt=">" :src="`/images/category/diamonds-shapes/${shape ? shape.charAt(0).toUpperCase() + shape.slice(1) : ''}.svg`" />
            </button>
        </div>
        <filter-select
            v-if="false"
            ref="filter-select"
            :components="{ OpenIndicator }"
            :options="['PREVIEW']"
            :closeOnSelect="false"
            placeholder="PREVIEW"
            :filterable="false"
            :searchable="false"
            :clearable="false">
            <template slot="option" slot-scope="option">
                <div>TEST TEST TEST</div>
            </template>
        </filter-select>
    </div>
</template>
<script>
import FilterSelect from "../../components/vue-select/src";

export default {
    name: "shapes",
    components: { "filter-select": FilterSelect },
    data() {
        return {
            shapes: ["oval", "emerald", "princess", "round", "pear", "cushion", "marquise", "radiant"],
            currentShapes: [],
            OpenIndicator: {
                render: (createElement) =>
                    createElement("img", {
                        attrs: {
                            src: require("../../../assets/images/icons/right.svg"),
                        },
                    }),
            },
        };
    },
    methods: {
        isIncludes(arr, value) {
            return arr && arr.length ? arr.includes(value.toLowerCase()) : false;
        },
        selectShape(shape) {
            this.$emit("changeShape", shape);
            this.currentShapes.length > 0 ? (this.currentShapes = []) : null;
            this.currentShapes.push(shape);
        },
    },
};
</script>
<style lang="scss" scoped>
#diamondShapes {
    width: 100%;
}
.btn-filters {
    border: solid 1px #cacaca;
    border-radius: 4px;
    margin-top: -1px;
    height: 40px;
}

[data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
    top: -20px;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 2px;
    background: #1c2132;
    color: #fff;
    font-size: 12px;
    font-family: sans-serif;
    white-space: nowrap;
}

[data-title]:hover::after {
    content: "";
    position: absolute;
    top: 0px;
    right: -10px;
    display: inline-block;
    color: #fff;
    border: 8px solid transparent;
}
</style>
