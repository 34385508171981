<template>
    <div id="home-store">
        <div v-if="isLoading" class="loading-wrapper">
            <loading></loading>
        </div>
        <div v-if="!isLoading">
            <div class="container">
                <paging-navigator :links="getNavigationLinks"></paging-navigator>
                <progress-bar v-if="false"></progress-bar>
                <store-info @showPopup="showPopup"></store-info>
                <!--
                    <filters-area v-if="currentStore && !currentStore.onlyLead" :filter-type="getSearchEngineType" :sort="routeProps.sort"></filters-area>
                    <loading v-if="isLoading"></loading>
                    <div v-if="!isLoading && currentStore && !currentStore.onlyLead">
                        <cards-area :search-engine-type="getSearchEngineType" :objectsToShow="objectsToShow"></cards-area>
                    </div>
                    <paging v-if="currentStore && !currentStore.onlyLead && getPagesCount && getPagesCount > 1" :pages="getPagesCount"></paging>
                -->
                <design-your-own v-if="false" class="design-your-own-section"></design-your-own>
                <common-questions
                    v-if="currentStore && currentStore.aboutTheStore && currentStore.aboutTheStore.length"
                    :questions="currentStore.aboutTheStore"
                    titleTag="div"
                    :title="commonQuestionsTitle"></common-questions>
                <!--<popular-rings></popular-rings>-->
                <!--<start-with-diamond></start-with-diamond>-->
                <!--<why-store></why-store>-->
                <how-it-works v-if="currentStore && !currentStore.onlyLead" showNumbers="true"></how-it-works>
                <google-maps-streetview></google-maps-streetview>
                <popular-jewelry-stores v-if="currentStore && getNearbyStores.length > 0" :stores="getNearbyStores" :state="getState"></popular-jewelry-stores>
                <Popup v-if="displayPopup" @closePopup="closePopupDialogue()"></Popup>
                <successfully-loaded v-if="showSuccessIndicator"></successfully-loaded>
            </div>
            <Jeweler-ai-banner class="jeweler-ai-banner"></Jeweler-ai-banner>
        </div>
    </div>
</template>

<script>
import Popup from "../wishlist/Popup.vue";
// import PopularRings from './sections/PopularRings.vue';
// import StartWithDiamond from "./sections/steps-explanation/StartWithDiamond";
// import WhyStore from "../../components/WhyStore";
import chunk from "lodash/chunk";
import sortBy from "lodash/sortBy";
import { mapActions, mapGetters, mapState } from "vuex";
import CommonQuestions from "../../components/CommonQuestions";
import Loading from "../../components/Loading.vue";
import PagingVue from "../../components/Paging.vue";
import PagingNavigatorVue from "../../components/PagingNavigator.vue";
import ProgressBar from "../../components/ProgressBar";
import SuccessfullyLoaded from "../../components/SuccessfullyLoaded.vue";
import CardsArea from "../category/components/CardsArea.vue";
import FiltersAreaVue from "../category/components/FiltersArea.vue";
import PopularJewelryStores from "../store-locator/stores-search-results/PopularJewelryStores.vue";
import DesignYourOwn from "./sections/DesignYourOwn.vue";
import GoogleMapsStreetview from "./sections/GoogleMapsStreetview";
import HowItWorks from "./sections/HowItWorks.vue";
import StoreInfo from "./sections/StoreInfo.vue";
import JewelerAiBanner from "../../components/JewelerAiBanner.vue";

export default {
    name: "home-store",
    props: ["url", "routeProps"],
    data() {
        return {
            title: `willyou | Shop Online. Pay & Pick up at Your Local Jeweler`,
            pageDescription: `Shop Online. Pay & Pick up at Your Local Jeweler`,
            path: "",
            seoImagePath: "/images/design-your-own-ring.png",
            seoKeywords: ["willyou", "rings", "tailor made rings", "wedding", "engagement ring"],
            elementsInPage: 12,
            displayPopup: false,
            requestedNearestJewelers: false,
        };
    },
    components: {
        Popup,
        "paging-navigator": PagingNavigatorVue,
        "design-your-own": DesignYourOwn,
        // 'popular-rings': PopularRings,
        "store-info": StoreInfo,
        // 'start-with-diamond': StartWithDiamond,
        loading: Loading,
        // 'why-store': WhyStore,
        "progress-bar": ProgressBar,
        "common-questions": CommonQuestions,
        "how-it-works": HowItWorks,
        "successfully-loaded": SuccessfullyLoaded,
        // "filters-area": FiltersAreaVue,
        // "cards-area": CardsArea,
        // paging: PagingVue,
        "google-maps-streetview": GoogleMapsStreetview,
        PopularJewelryStores,
        "Jeweler-ai-banner": JewelerAiBanner,
    },
    computed: {
        ...mapState("willyou", ["isLoading", "currentStore", "diamonds", "rings", "totalPagesToShow", "states", "storesAccurate"]),
        ...mapGetters("willyou", ["getCurrentRings", "getCurrentMeta"]),
        getStoreName() {
            return this.currentStore && this.currentStore.companyName ? this.currentStore.companyName : "";
        },
        getNavigationLinks() {
            let breadcrumbs = [
                { name: "Home", url: "/" },
                { name: "Store Locator", url: "/jewelry-stores" },
            ];
            this.currentStore && this.currentStore.state
                ? breadcrumbs.push({
                      name: this.currentStore.state,
                      url: `/jewelry-stores/${this.currentStore.state}`,
                  })
                : null;
            this.currentStore && this.currentStore.city
                ? breadcrumbs.push({
                      name: this.currentStore.city,
                      url: `/jewelry-stores/${this.currentStore.state}/${this.currentStore.city}`,
                  })
                : null;
            this.currentStore && this.currentStore.companyName && this.currentStore.url
                ? breadcrumbs.push({
                      name: this.currentStore.companyName,
                      url: `/jewelers/${this.currentStore.url}`,
                  })
                : null;
            return breadcrumbs;
        },
        commonQuestionsTitle() {
            return this.currentStore && this.currentStore.companyName ? `A word from the team at ${this.currentStore.companyName}` : "A word from the team";
        },
        isDiamonds() {
            return this.routeProps.searchenginetype === "diamonds";
        },
        getSearchEngineType() {
            return this.isDiamonds ? "diamonds" : "rings";
        },
        getPagesCount() {
            return this.isDiamonds
                ? this.totalPagesToShow
                    ? this.totalPagesToShow
                    : Math.ceil(this.sortedDiamonds.length / this.elementsInPage)
                : Math.ceil(this.sortedRings.length / this.elementsInPage);
        },
        sortedDiamonds() {
            return this.diamonds;
        },
        objectsToShow() {
            return this.isDiamonds ? this.diamondsToShow : this.ringsToShow;
        },
        diamondsToShow() {
            return this.sortedDiamonds;
        },
        // rings computed methods:
        sortedRings() {
            switch (this.routeProps.sort) {
                case "style":
                    return sortBy(this.getCurrentRings, ["ringcolor"]);
                default:
                    return this.getCurrentRings;
            }
        },
        currentPage() {
            return parseInt(this.$route.query.page ? this.$route.query.page : 1);
        },
        ringsToShow() {
            return chunk(this.sortedRings, this.elementsInPage)[this.currentPage - 1];
        },
        locations() {
            return this.currentStore && this.currentStore.companyName
                ? [
                      {
                          ...this.currentStore.location,
                          jewelerName: this.currentStore.companyName,
                      },
                  ]
                : [];
        },
        getNearbyStores() {
            if (!this.requestedNearestJewelers) this.requestNearestJewelersIfNeeded();

            if (!this.storesAccurate) return [];
            return this.storesAccurate.filter((store) => store.internalID !== this.currentStore.internalID);
        },
        getState() {
            return this.currentStore && this.currentStore.state && this.currentStore.city ? `${this.currentStore.city}, ${this.currentStore.state}` : "";
        },
        showSuccessIndicator() {
            return !this.isLoading;
        },
    },
    methods: {
        ...mapActions("willyou", ["getStoreData", "setCurrentPage", "setCurrentPath", "getDiamonds", "getStoreData", "getStoresByCityState"]),
        requestNearestJewelersIfNeeded() {
            if (this.currentStore && this.currentStore.city && this.currentStore.state && this.storesAccurate.length === 0)
                this.getStoresByCityState({
                    city: this.currentStore.city,
                    state: this.currentStore.state,
                });
        },
        showPopup() {
            this.displayPopup = true;
        },
        closePopupDialogue() {
            this.displayPopup = false;
        },
    },
    mounted() {
        this.getStoreData({ url: this.url, router: this.$router });
        this.$route ? this.setCurrentPage(this.$route.name) : null;
        this.$route ? this.setCurrentPath(this.$route.fullPath) : null;

        let filtersObj = this.routeProps;
        this.getDiamonds(filtersObj);
    },
    watch: {
        routeProps(newVal, oldVal) {
            if (this.currentStore && this.$route.params.url && this.currentStore.url !== this.$route.params.url)
                this.getStoreData({ url: this.url, router: this.$router });

            let filtersObj = this.routeProps;
            this.getDiamonds(filtersObj);

            if (Object.values(newVal).find((prop) => typeof prop !== "undefined").length > 0) {
                setTimeout(
                    () =>
                        this.$scrollTo("#diamond-filters", 500, {
                            offset: -80,
                            force: true,
                        }),
                    3500
                );
            }
        },
    },
    metaInfo() {
        if (this.currentStore && this.currentStore.hideIndex && this.currentStore.hideIndex === "true" && this.getCurrentMeta) {
            const currentMeta = { ...this.getCurrentMeta };
            currentMeta.meta.find((item) => item.name === "robots").content = "noindex, nofollow";

            return currentMeta;
        } else return this.getCurrentMeta;
    },
};
</script>

<style lang="scss" scoped>
#home-store {
    min-height: 100vh;
    padding-top: 160px;
    .design-your-own-section {
        margin-bottom: 80px;
    }

    .jeweler-ai-banner {
        margin-top: 64px;
    }

    .loading-wrapper {
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;

        #loading {
            padding-top: 130px;
        }
    }
}
</style>
