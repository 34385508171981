<template>
    <div id="interested-in">
        <div class="row">
            <div
                @click="choose('ring')"
                class="col-12 col-md-6 p-3 btn btn-filters btn-filters-lg last-btn btn-custom-style"
                classs="engagement-rings col-12 col-md-6 text-center btn btn-filters btn-filters-sm p-2"
                :class="{ 'rnd-primary-btn': chosen === 'ring', 'rnd-secondary-btn': chosen === 'diamond' }">
                <engagement-ring-icon :color="engagementRingIconColor"></engagement-ring-icon>
                <div class="mt-2 text-uppercase">Engagement ring</div>
            </div>
            <div
                @click="choose('diamond')"
                class="col-12 col-md-6 p-3 btn btn-filters btn-filters-lg last-btn btn-custom-style"
                :class="{ 'rnd-primary-btn': chosen === 'diamond', 'rnd-secondary-btn': chosen === 'ring' }">
                <diamond-icon :color="diamondIconColor"></diamond-icon>
                <div class="mt-1 text-uppercase">Diamond</div>
            </div>
        </div>
    </div>
</template>
<script>
import DiamondIcon from "./DiamondIcon.vue";
import EngagementRingIcon from "./EngagementRingIcon.vue";
export default {
    components: { DiamondIcon, EngagementRingIcon },
    name: "interested-in",
    data() {
        return {
            chosen: "ring",
        };
    },
    computed: {
        engagementRingIconColor() {
            return this.chosen === "ring" ? "white" : "#ff4d68";
        },
        diamondIconColor() {
            return this.chosen === "diamond" ? "white" : "#ff4d68";
        },
    },
    methods: {
        choose(chosen = "ring") {
            this.chosen = chosen;
            this.$emit("choose", chosen);
        },
    },
};
</script>

<style lang="scss" scoped>
#interested-in {
    width: 100%;
    color: #4a4a4a;

    .row {
        margin: 0 !important;
    }
    .engagement-rings,
    .diamonds {
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid rgb(255, 10, 83);
        transition: background 0.1s;
    }
    .engagement-rings {
        border-radius: 4px 0 0 4px !important;
    }
    .diamonds {
        border-radius: 0 4px 4px 0 !important;
    }
    .btn-filters {
        border-radius: 4px;
        border: solid 1px #cacaca !important;
        background-color: #ffffff;
        color: #4a4a4a;
        font-size: 14px;
    }
    .btn-custom-style {
        padding: 0 22px;
    }
    .btn-filters:hover {
        border: solid 1px #fd8498;
    }
    .rnd-primary-btn {
        background-color: #fd8498;
    }

    @media (max-width: 767px) {
        .mobile-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            width: 50%;
        }
    }

    @media screen and (max-height: 784px) {
        .engagement-rings,
        .diamonds {
            height: 106px;
            padding: 2px !important;
            border-radius: 4px !important;
        }
    }
}
</style>
